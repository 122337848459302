import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import CurrentLecture from './CurrentLecture';
import Schedule from './Schedule';
import Tests from './Tests';
import PointsTracker from './PointsTracker';
import './App.css';

function App() {
    // Check localStorage for dark mode preference on initial load
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedDarkMode = localStorage.getItem('isDarkMode');
        return savedDarkMode ? JSON.parse(savedDarkMode) : false;
    });

    useEffect(() => {
        // Apply dark mode class to body based on the current state
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('isDarkMode', JSON.stringify(newMode));
            return newMode;
        });
    };

    return (
        <Router>
            <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
                <Navbar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

                <Routes>
                    <Route path="/" element={<CurrentLecture isDarkMode={isDarkMode} />} />
                    <Route path="/all-lectures" element={<Schedule />} />
                    <Route path="/tests" element={<Tests />} />
                    <Route path="/points" element={<PointsTracker />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
